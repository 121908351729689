@charset "UTF-8";

.contact_container {
    padding-top: 80px;
    padding-bottom: 120px;
    border-bottom: 1px solid #ccc;

    @include res($resmd) {
        padding-top: 40px;
        padding-bottom: 60px;
    }
}

.contact_box1 {
    form {
        ul {
            display: flex;
            align-items: center;
            text-align: left;
            padding-top: 20px;
            padding-bottom: 20px;
            border-top: 1px solid #ccc;

            li {
                &:first-child {
                    display: flex;
                    justify-content: flex-start;
                    width: 30.9677419355%;
                    padding-left: 24px;
                    box-sizing: border-box;
                }

                &:last-child {
                    width: 69.0322580645%;
                    box-sizing: border-box;
                    padding-right: 24px;
                    display: flex;

                    input,
                    textarea {
                        @include fontsize(16);
                        padding: 10px;
                        box-sizing: border-box;
                        border-radius: 6px;
                        border: 1px solid #999;
                    }

                    input {
                        height: 48px;
                    }
                }
            }

            .contact_text {
                input {
                    width: 100%;
                }

                textarea {
                    width: 100%;
                }
            }

            .contact_nam {
                display: flex;
                align-items: center;

                input {
                    width: 103px;

                    &+i {
                        font-style: normal;
                        display: inline-block;
                        margin-left: 7px;
                        margin-right: 7px;
                        background-color: #999;
                        width: 10px;
                        height: 1px;
                    }
                }

                .contact_nam84 {
                    width: 84px;
                }
            }

            .contact_nam_post {
                .contact_left {
                    display: flex;
                    align-items: center;
                }

                .contact_right {
                    width: 201px;
                    height: 48px;
                    margin-left: 42px;

                    button {
                        @include fontsize(14);
                        width: 100%;
                        height: 100%;
                        border-radius: 6px;
                        background-color: #999;
                        color: #fff;
                        cursor: pointer;
                        border: 0;
                        padding: 0;
                    }
                }
            }

            .contact_radio {
                input {
                    display: none;

                    &+label {
                        position: relative;
                        cursor: pointer;
                        padding-left: 30px;

                        &:first-of-type {
                            margin-right: 35px;
                        }

                        &::after,
                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                        }

                        &::before {
                            top: 0;
                            left: 0;
                            width: 18px;
                            height: 18px;
                            background-color: #e8e8e8;
                            border-radius: 50%;
                        }
                    }
                }

                input {
                    &:checked {
                        &+label {
                            &:after {
                                top: 5px;
                                left: 5px;
                                width: 8px;
                                height: 8px;
                                background: #333;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }

            &:last-of-type {
                border-bottom: 1px solid #ccc;
                margin-bottom: 80px;
            }
        }

        .contact_more {
            position: relative;
            max-width: 352px;
            height: 60px;
            margin: 0 auto;

            input {
                @include fontsize(16);
                cursor: pointer;
                display: block;
                width: 100%;
                height: 100%;
                border: none;
                background-color: #0f0f65;
                color: #fff;
                border: 0;
                box-sizing: border-box;
            }

            span {
                @include trans2(3);
                position: absolute;
                display: block;
                bottom: 28px;
                right: 33px;
                width: 40px;
                pointer-events: none;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &:hover {
                span {
                    @include trans2(3);
                    right: 20px;
                }
            }
        }
    }

    @include res($resmd) {
        form {
            ul {
                display: block;
                padding-top: 15px;
                padding-bottom: 15px;

                li {
                    &:first-child {
                        width: 100%;
                        padding-left: 0;
                        padding-bottom: 7px;
                    }

                    &:last-child {
                        width: 100%;
                        padding-right: 0;
                    }
                }

                .contact_text {
                    textarea {
                        height: 50px;
                    }
                }

                .contact_nam_post {
                    flex-direction: column;
                    align-items: flex-start;

                    .contact_left {
                        padding-bottom: 10px;
                    }

                    .contact_right {
                        width: 210px;
                        height: 36px;
                        margin-left: 0;
                    }
                }

                .contact_nam_tel {
                    input {
                        width: auto;
                        max-width: 97px;
                    }
                }

                .contact_radio {
                    padding-top: 13px;
                    flex-direction: column;

                    label {
                        padding-bottom: 20px;

                        &:last-child {
                            padding-bottom: 0;
                        }
                    }
                }

                &:last-of-type {
                    margin-bottom: 40px;
                }
            }
        }
    }

    @include res($res500) {
        form {
            ul {
                .contact_nam_tel {
                    input {
                        width: 28.9552238806%;
                    }
                }
            }
        }
    }
}


.mw_wp_form ul {
    .contact_text {
        position: relative;

        .error {
            position: absolute;
            top: 14px;
            left: 7px;
            font-size: 14px;
            color: #dc3a00 !important;
        }
    }

    .contact_nam_post {
        position: relative;

        .error {
            position: absolute;
            top: 14px;
            left: 7px;
            font-size: 14px;
            color: #dc3a00 !important;
        }
    }
    .contact_nam_tel {
        .error {
            font-size: 14px;
            color: #dc3a00 !important;
        }
    }
}


.mw_wp_form_confirm #search_add {
    display: none !important;
}

.form_complete_message {
    line-height: 2.0;
}
