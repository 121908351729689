@charset "UTF-8";

.company_box1 {
    padding-bottom: 120px;
    padding-top: 80px;
    .company_table1 {
        ul {
            li {
                &:first-child {
                    width: 17.2043010753%;
                    padding-left: 2.58064516129%;
                }
                &:last-child {
                    width: 82.7956989247%;
                    padding-left: 3.54838709677%;
                }
            }
        }
    }
    @include res($resmd) {
        padding-bottom: 60px;
        padding-top: 40px;
        .company_table1 {
            ul {
                li {
                    &:first-child {
                        padding-left: 0;
                        width: 20.8955223881%;
                    }
                    &:last-child {
                        width: 79.1044776119%;
                        padding-left: 4.17910447761%;
                    }
                }
            }
        }
    }
}

.company_box2 {
    padding-bottom: 120px;
    border-bottom: 1px solid #999;
    img {
        width: 100%;
        height: auto;
    }
    .company_table2 {
        ul {
            display: flex;
            li {
                text-align: left;
                padding-top: 14px;
                padding-bottom: 14px;
                &:first-child {
                    border-top: 1px solid #0f0f65;
                    box-sizing: border-box;
                    width: 18.1720430108%;
                    padding-left: 2.58064516129%;
                }
                &:last-child {
                    border-top: 1px solid #ccc;
                    box-sizing: border-box;
                    width: 81.8279569892%;
                    padding-left: 2.58064516129%;
                    .company_img_21 {
                        position: relative;
                        margin-top: 24px;
                        span {
                            position: absolute;
                            display: block;
                            width: 43.0263157895%;
                        }
                        .company_img1 {
                            top: 0;
                            left: 0;
                            transform: rotate(-4deg);
                        }
                        .company_img2 {
                            top: 35.3211009174%;
                            left: 34.2279511533%;
                            transform: rotate(2deg);
                        }
                        &::before {
                            content:"";
                            display: block;
                            padding-top: 57.2591587517%;
                        }
                    }
                    .company_img_44 {
                        position: relative;
                        margin-top: 24px;
                        .company_img3 {
                            position: absolute;
                            display: block;
                            top: -36.4545454545%;
                            right: 7.73405698779%;
                            transform: rotate(6.51deg);
                            width: 44.4504748982%;
                        }
                        &::before {
                            content:"";
                            display: block;
                            padding-top: 27.9511533243%;
                        }
                    }
                }
            }
            &:last-child {
                border-bottom: 1px solid #ccc;
            }
        }
    }
    @include res($res900) {
        .company_table2 {
            ul {
                li {
                    &:last-child {
                        .company_img_44 {
                            .company_img3 {
                                right: 1.734057%;
                                width: 39.450475%;
                            }
                        }
                    }
                }
            }
        }
    }
    @include res($resmd) {
        padding-bottom: 60px;
        .company_table2 {
            line-height: 1.78571428571;
            ul {
                position: relative;
                display: block;
                &::before {
                    position: absolute;
                    width: 100%;
                    content: "";
                    display: block;
                    top: 0;
                    left: 0;
                    border-top: 1px solid #ccc;
                }
                &::after {
                    position: absolute;
                    width: 34.0298507463%;
                    content: "";
                    display: block;
                    top: 0;
                    left: 0;
                    border-top: 1px solid #0f0f65;
                }

                li {
                    &:first-child {
                        border-top: 0;
                        width: 100%;
                        padding-left: 0;
                        padding-bottom: 0;
                    }
                    &:last-child {
                        width: 100%;
                        border-top: 0;
                        padding-top: 0;
                        padding-left: 0;
                        .br_res {
                            display: none;
                        }
                        .company_img_21, .company_img_44 {
                            position: static;
                            margin-top: 15px;
                            .company_img1 {
                                padding-bottom: 7px;
                            }
                            .company_img1, .company_img2, .company_img3 {
                                position: static;
                                transform: none;
                                width: 69.552238806%;
                            }
                            &::before {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
