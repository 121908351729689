@charset "UTF-8";

.parts2_maxwidth1120 {
    box-sizing: border-box;
    margin: auto;
    max-width: 1160px;
    padding-right: 20px;
    padding-left: 20px;
}

.parts2_maxwidth930 {
    box-sizing: border-box;
    margin: auto;
    max-width: 970px;
    padding-right: 20px;
    padding-left: 20px;
}

.parts2_pdnTop {
    padding-top: 80px;
    @include res($res900) {
        padding-top: 50px;
    }
}

.parts2_secFirst {
    background-image: url("../img/concept/concept_main.jpg");
    background-color: #000;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.parts2_titleBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #fff;
    height: 240px;
    @include font_m;

    .title_box1Icn {
        width: 24px;
        padding-bottom: 28px;

        img {
            width: 100%;
            height: auto;
            margin: 0;
            padding: 0;
            vertical-align: top;
        }
    }

    .title_box1En {
        @include fontLus;
    }

    .title_box1Ja {}

    p {
        &:first-of-type {
            &::after {
                content: "";
                display: block;
                border-top: 1px solid #fff;
                margin-top: 18px;
                margin-bottom: 18px;
                width: 24px;
            }
        }
    }
    @include res($res414) {
        height: 120px;

        .title_box1Icn {
            width: 13px;
            height: 16px;
            padding-bottom: 10px;
        }

        p {
            &:first-of-type {
                &::after {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    width: 13px;
                }
            }
        }
    }
}

.parts2_worksSin_title {
    border-top: 2px solid #999;
    border-bottom: 2px solid #999;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 24px;
    @include font_m;
    text-align: left;
    @include res($resmd) {
        padding-left: 0;
    }
}

.parts2_worksSin_name {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 25px;
    @include font_m;
    text-align: left;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 46px;
    @include res($resmd) {
        padding-left: 0;
        margin-bottom: 23px;
    }
}

.parts2_works_sin_subTitle {
    position: relative;
    padding-left: 24px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 40px;
    text-align: left;
    border-left: 1px solid #333;
    @include font_m;
    &::after {
        position: absolute;
        bottom: -10px;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        border-bottom: 1px solid #ccc;
    }
    @include res($resmd) {
        border-left: 2px solid #999;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 15px;
        br {
            display: none;
        }
    }
}

.parts2_works_sin_text {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 85px;
    text-align: left;
    line-height: 1.8125;
    @include res($resmd) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 45px;
        line-height: 1.78571428571;
    }
}

.parts2_btmSelect {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 200px;

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #fff;
        @include font_m;
        position: relative;
        &::before {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            content: "";
            background-color: #000;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .concept_mainTitle {
            padding-bottom: 3px;
            letter-spacing: 0.1rem;
        }

        .concept_subTitle {
            @include fontLus;
            color: #ccc;
            letter-spacing: 0.1rem;
        }
    }
    @include res($resmd) {
        width: 100%;
        height: 100px;
    }
}

.parts2_flowWrap {
    display: flex;

    .flow_left {
        width: 38.8392857143%;
    }

    .flow_right {
        width: 61.25%;
        text-align: left;
        padding-left: 7.14285714286%;
    }

    img {
        width: 100%;
        height: auto;
    }
    @include res($resmd) {
        display: block;
        .flow_left {
            width: 100%;
            padding-bottom: 32px;
        }

        .flow_right {
            width: 100%;
            padding-left: 0;
        }
    }
}

.parts2_flowTitle {
    display: flex;
    align-items: flex-start;
    border-top: 1px solid #333;

    .flow_num {
        position: relative;
        width: 64px;
        box-sizing: border-box;
        margin-left: 16px;
        @include fontLus;
        border-left: 1px solid #333;
        border-right: 1px solid #333;
        background-color: #fff;

        span {
            display: block;

            &:first-child {
                padding-top: 12px;
                @include fontsize(14);
            }

            &:last-child {
                @include fontsize(32);
            }
        }

        &::after,
        &::before {
            content: "";
            position: absolute;
            right: -1px;
            height: 0;
            width: 0;
            border-left: 32px solid transparent;
            border-right: 32px solid transparent;
        }
        &::before {
            bottom: -13px;
            border-top: 13px solid #333;
        }
        &::after {
            bottom: -12px;
            border-top: 13px solid #fff;
        }
    }

    .flow_title {
        @include font_m;
        padding-top: 35px;
        padding-left: 25px;
    }

    @include res($resmd) {
        .flow_num {
            width: 58px;
            span {
                &:first-child {
                    @include fontsize(12);
                }

                &:last-child {
                    line-height: 1.2;
                    @include fontsize(27);
                }
            }
            &::after,
            &::before {
                border-left: 29px solid transparent;
                border-right: 29px solid transparent;
            }
            &::before {
                bottom: -13px;
                border-top: 13px solid #333;
            }
            &::after {
                bottom: -12px;
                border-top: 13px solid #fff;
            }
        }

        .flow_title {
            @include font_m;
            padding-top: 25px;
            padding-left: 20px;
        }
    }
}

.parts2_flowTitle2 {
    .flow_num {
        color: #fff;
        background-color: #333;
        &::after {
            bottom: -12px;
            border-top: 13px solid #333;
        }
    }
}

.parts2_flowTxt {
    text-align: justify;
    letter-spacing: .01em;
    line-height: 1.8125;
}

.parts2_flowBtm {
    display: flex;
    div {
        width: 48.6956521739%;
        &:first-child {
            margin-right: 2.31884057971%;
        }
    }
}

.parts2_priceTitle {
    position: relative;
    @include font_m;
    text-align: left;
    padding-top: 28px;
    padding-bottom: 26px;
    padding-left: 45px;
    border-top: 1px solid #333;
    border-bottom: 1px solid #ccc;
    &::before, &::after {
        content: "";
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
    }
    &::before {
        width: 43px;
        height: 32px;
        background-color: #fff;
    }
    &::after {
        border-top: 16px solid #333;
        border-right: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 16px solid #333;
    }
    @include res($resmd) {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

.parts2_companyTitle {
    padding-bottom: 60px;
    .title_box1Ja {
        @include font_m;
    }
    .title_box1En {
        @include fontLus;
        color: #999;
    }
    p {
        &:first-child {
            padding-bottom: 7px;
        }
    }
    @include res($resmd) {
        padding-bottom: 40px;
    }
}

.parts2_more2 {


    position: relative;
    display: flex;
    width: 352px;
    height: 60px;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: #0f0f64;
    @include lus_b;
    @include fontsize(16);

    span {
        @include trans2(3);
        position: absolute;
        display: block;
        bottom: 28px;
        right: 33px;
        width: 40px;
        pointer-events: none;

        img {
            width: 100%;
            height: auto;
        }
    }

    &:hover {
        span {
            @include trans2(3);
            right: 20px;
        }
    }

    @include res($resmd) {
        width: 280px;
        height: 40px;

        &:hover {
            span {
                right: 10px;
            }
        }

        span {
            bottom: 20px;
            right: 20px;
        }
    }
}

.parts2_table {
    ul {
        display: flex;
        text-align: left;
        border-bottom: 1px solid #ccc;

        li {
            padding-top: 14px;
            padding-bottom: 14px;

            &:first-child {
                position: relative;
                width: 27.8931750742%;
                padding-left: 9px;
                box-sizing: border-box;

                &::after {
                    content: "";
                    display: block;
                    height: 63.829787234%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    border-right: 1px solid #ccc;
                }
            }

            &:last-child {
                width: 72.1068249258%;
                padding-left: 16px;
                box-sizing: border-box;
            }
        }

        &:first-child {
            border-top: 1px solid #ccc;
        }
    }
}

.parts2_table2 {
    margin-bottom: 9px;
    border: 1px solid #ccc;
    ul {
        display: flex;
        border-bottom: 1px solid #ccc;
        li {
            width: 33.3333333333%;
            text-align: right;
            box-sizing: border-box;
            padding: 15px 17px;
            padding-top: 15px;
            border-right: 1px solid #ccc;
            &:last-child {
                border-right: 0;
            }
        }
        &:first-child {
            background-color: #f0f1ec;
            li {
                text-align: center;
            }
        }
        &:last-child {
            border-bottom: 0;
        }
    }
    & + aside {
        text-align: right;
    }
    @include res($res800) {
        ul {
            li {
                padding: 15px 8px;
            }
        }
    }
}

.parts2_blogTitle {
    position: relative;
    text-align: left;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    padding-bottom: 20px;
    a {
        display: inline-block;
    }

    .blog_time, .blog_title {
        margin-left: 24px;
    }
    .blog_time {
        position: relative;
        display: inline-block;
        color: #999;
        @include fontLus;
        letter-spacing: 0.2em;
        padding-bottom: 10px - 3px;
        margin-bottom: 10px;
        &::before {
            position: relative;
            top: 30px - 3px;
            left: 0;
            content: "";
            display: block;
            width: 20px;
            border-bottom: 1px solid #ccc;
        }
    }
    .blog_title {
        @include font_m;
    }
    .blog_cat {
        position: absolute;
        top: 20px;
        right: 0;
        a {
            color: #999;
        }
    }
    &:last-child {
        border-bottom: 1px solid #ccc;
    }

    @include res($resmd) {
        padding-top: 15px;
        padding-bottom: 15px;
        .blog_time, .blog_title {
            margin-left: 0;
        }
        .blog_time {
            &::before {
                top: 24px;
            }
        }
        .blog_cat {
            top: 15px;
        }
    }
}

.parts2_blogTitle2 {
    position: relative;
    @include fontsize(14);
    color: #999;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 9px;
    padding-left: 35px;
    margin-bottom: 24px;
    &::before {
        position: absolute;
        top: 50%;
        left: -7px;
        margin: auto;
        content: "";
        display: block;
        width: 40px;
        border-top: 1px solid #999;
        transform: rotate(-53deg);
    }
    @include res($resmd) {
        margin-bottom: 12px;
    }
}

.parts2_blogList {
    li {
        text-align: left;
        border-top: 1px solid #ccc;
        padding-top: 10px;
        padding-bottom: 10px;
        a {
            display: block;
            padding-left: 8px;
        }
        &:last-child {
            border-bottom: 1px solid #ccc;
        }
    }
    @include res($resmd) {
        li {
            a {
                padding-left: 0;
            }
        }
    }
}

.parts2_blog_select {
    position: relative;
    height: 40px;

    select {
        appearance: none;
        width: 100%;
        height: 100%;
        @include fontsize(13);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 17px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #808080;
        border-radius: 4px;
        cursor: pointer;
    }

    &::-ms-expand {
        display: none;
    }

    &::before, &::after {
        position: absolute;
        content: "";
        display: block;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
    }
    &::before {
        top: 10px;
        right: 14px;
        border-bottom: 8px solid #1a1a1a;
    }
    &::after {
        bottom: 10px;
        right: 14px;
        border-top: 8px solid #1a1a1a;
    }
}

.parts2_must {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dc3a00;
    color: #fff;
    width: 40px;
    height: 18px;
    @include fontsize(13);
    margin-left: 16px;
    @include res($resmd) {
        margin-left: 12px;
    }
}

.parts2_navBtm {
    position: relative;
    border-top: 1px solid #666;
    padding-bottom: 80px;

    div {
        position: absolute;
        border: 1px solid #666;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-sizing: border-box;

        a {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            span {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                display: block;
                width: 21px;
            }
        }

        &::before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }

    .works_sin_btm_left {
        top: 40px;
        left: 24px;
        a {
            span {
                transform: scale(-1, 1);
            }
        }
    }
    .works_sin_btm_right {
        top: 40px;
        right: 24px;
    }
    @include res($resmd) {
        padding-bottom: 120px;
        .works_sin_btm_left {
            top: 20px;
            left: 0;
        }
        .works_sin_btm_right {
            top: 20px;
            right: 0;
        }
    }
}
