@charset "UTF-8";

.pr_font11_12_ {
    @include fontsize(14);
    @include res($resmd) {
        @include fontsize(12);
    }
}

.pr_font14__12 {
    @include fontsize(14);
    @include res($res414) {
        @include fontsize(12);
    }
}

.pr_font14_13_ {
    @include fontsize(14);
    @include res($resmd) {
        @include fontsize(13);
    }
}
.pr_font14_12_ {
    @include fontsize(14);
    @include res($resmd) {
        @include fontsize(12);
    }
}

.pr_font14_11_ {
    @include fontsize(14);
    @include res($resmd) {
        @include fontsize(11);
    }
}

.pr_font16_14_ {
    @include fontsize(16);
    @include res($res800) {
        @include fontsize(14);
    }
}

.pr_font16_14_2nd {
    @include fontsize(16);
    @include res($resmd) {
        @include fontsize(14);
    }
}

.pr_font16_13_ {
    @include fontsize(16);
    @include res($resmd) {
        @include fontsize(13);
    }
}

.pr_font18_14_ {
    @include fontsize(18);
    @include res($res800) {
        @include fontsize(14);
    }
}

.pr_font18_14_2nd {
    @include fontsize(18);
    @include res($resmd) {
        @include fontsize(14);
    }
}

.pr_font22__14 {
    @include fontsize(22);
    @include res($res414) {
        @include fontsize(14);
    }
}

.pr_font22__18 {
    @include fontsize(22);
    @include res($res414) {
        @include fontsize(18);
    }
}

.pr_font22_18_ {
    @include fontsize(22);
    @include res($resmd) {
        @include fontsize(18);
    }
}

.pr_font22_20_18 {
    @include fontsize(22);
    @include res($res800) {
        @include fontsize(20);
    }
    @include res($res414) {
        @include fontsize(18);
    }
}

.pr_font26__18 {
    @include fontsize(26);
    @include res($res414) {
        @include fontsize(18);
    }
}

.pr_font26_18_ {
    @include fontsize(26);
    @include res($resmd) {
        @include fontsize(18);
    }
}
.pr_font26_19_ {
    @include fontsize(26);
    @include res($resmd) {
        @include fontsize(19);
    }
}

.pr_font26_20_ {
    @include fontsize(26);
    @include res($resmd) {
        @include fontsize(20);
    }
}

.pr_font30__18 {
    @include fontsize(30);
    @include res($res414) {
        @include fontsize(18);
    }
}

.pr_font30_20_ {
    @include fontsize(30);
    @include res($resmd) {
        @include fontsize(20);
    }
}

.pr_font32__22 {
    @include fontsize(32);
    @include res($res414) {
        @include fontsize(22);
    }
}
