@charset "UTF-8";

.concept_box2 {
    padding-top: 165px;
    padding-bottom: 200px;
    box-sizing: border-box;
    display: flex;
    img {
        width: 100%;
        height: auto;
    }
    .concept_left {
        position: relative;
        width: 57.4107142857%;
        padding-top: 43px;
        padding-left: 8.83928571429%;
        box-sizing: border-box;

        .concept_box2Title {
            position: absolute;
            top: -89px;
            left: 40px;
            writing-mode: vertical-rl;
            @include font_m;
            text-align: left;
            line-height: 1.8125;
            .concept_box2Title2 {
                padding-top: 40px;
            }
        }
        .concept_leftTop {
            padding-bottom: 16px;
        }
        .concept_leftBtm {
            display: flex;
            justify-content: space-between;
            .concept_btmL {
                width: 47.0588235294%;
            }
            .concept_btmR {
                width: 50%;
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
        
                > div {
                    width: 100%;
                }
            }
        }
    }
    .concept_right {
        width: 42.5892857143%;
        padding-left: 5.80357142857%;
        box-sizing: border-box;
        text-align: left;
        .concept_box2Title {
            @include font_m;
            line-height: 1.80769230769;
            padding-bottom: 30px;
        }
        .concept_txt {
            line-height: 1.8125;
        }
    }

    @include res($res900) {
        .concept_left {
            width: 55%;
            padding-left: 7%;
            .concept_box2Title {
                left: -4px;
            }
        }
        .concept_right {
            width: auto;
            padding-left: 3%;
        }
    }

    @include res($resmd) {
        padding-top: 60px;
        padding-bottom: 60px;
        display: block;
        .concept_left {
            position: static;
            width: 100%;
            padding-top: 0;
            padding-left: 0;
            .concept_box2Title {
                position: static;
                writing-mode: horizontal-tb;
                padding-bottom: 40px;
                .concept_box2Title2 {
                    padding-top: 0;
                    padding-left: 40px;
                }

            }
        }
        .concept_right {
            width: 100%;
            padding-top: 60px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.concept_box3 {
    display: flex;
    .concept_left {
        a {
            &::before {
                background-image: url("../img/concept/concept_img06.jpg");
            }
        }
    }
    .concept_right {
        a {
            &::before {
                background-image: url("../img/concept/concept_img05.jpg");
            }
        }
    }
    @include res($resmd) {
        display: block;
    }
}
