@charset "UTF-8";

.blog_sin_box1 {
    .blog_sin_title {
        border-top: 1px solid #333;
        border-bottom: 1px solid #ccc;
        margin-bottom: 48px;
    }

    .blog_sin_text1 {
        padding-bottom: 60px;
    }

    .blog_sin_img {
        padding-bottom: 70px;
    }

    .blog_sin_text2 {
        padding-bottom: 100px;
    }

    @include res($resmd) {
        .blog_sin_title {
            margin-bottom: 24px;
        }

        .blog_sin_text1 {
            padding-bottom: 49px;
        }

        .blog_sin_img {
            padding-bottom: 60px;
        }

        .blog_sin_text2 {
            padding-bottom: 80px;
        }
    }
}

.blog_content {
    padding-bottom: 75px;

    p {
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 10px;
        text-align: left;
        line-height: 1.8125;

        img {
            width: 100%;
            height: auto;
        }
    }

    @include res($resmd) {
        padding-bottom: 35px;

        p {
            padding-left: 0px;
            padding-right: 0px;
            @include fontsize(14);
        }
    }
}
