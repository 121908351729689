@charset "UTF-8";

.works_sin_container {
    padding-top: 80px;
    padding-bottom: 120px;
    border-bottom: 1px solid #999;

    .works_sin_wrap {
        display: flex;

        .works_sin_left {
            width: 65.8035714286%;
            padding-right: 11.4285714286%;
        }

        .works_sin_right {
            width: 23.0357142857%;

            article {
                padding-bottom: 40px;

                .works_sin_right_text {
                    border-bottom: 1px solid #ccc;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-top: 12px;
                    padding-bottom: 12px;

                    span {
                        display: flex;
                        align-items: center;
                        width: 21px;
                    }
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    @include res($resmd) {
        padding-top: 40px;
        padding-bottom: 35px;

        .works_sin_wrap {
            display: block;

            .works_sin_left {
                width: 100%;
                padding-right: 0;
            }

            .works_sin_right {
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                article {
                    width: 48.6567164179%;
                    padding-bottom: 28px;
                }
            }
        }
    }
}

.works_sin_box2 {
    .works_sin_img {
        padding-left: 24px;
        padding-bottom: 33px;
    }

    @include res($resmd) {
        .works_sin_img {
            padding-left: 0;
        }
    }
}

.works_sin_box5 {
    padding-top: 40px;
    .works_sin_wrap {
        display: flex;
        justify-content: space-between;
        padding-bottom: 97px;

        .works_sin_img {
            width: 47.7611940299%;
            padding-left: 24px;
        }

        .works_sin_table {
            width: 45.7259158752%;
        }
    }

    @include res($resmd) {
        .works_sin_wrap {
            display: block;
            padding-bottom: 48px;

            .works_sin_img {
                width: 100%;
                padding-left: 0;
                padding-bottom: 20px;
            }

            .works_sin_table {
                width: 100%;
            }
        }
    }
}

.works_sin_content {
    p {
        padding-left: 24px;
        padding-right: 24px;
        text-align: left;
        line-height: 1.8125;
        @include fontsize(16);

        img {
            width: 100%;
            height: auto;
            padding-bottom: 10px;
        }
    }

    h2 {
        position: relative;
        padding-left: 24px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 20px;
        margin-bottom: 40px;
        text-align: left;
        border-left: 1px solid #333;
        @include font_m;
        @include fontsize(22);

        &::after {
            position: absolute;
            bottom: -10px;
            left: 0;
            content: "";
            display: block;
            width: 100%;
            border-bottom: 1px solid #ccc;
        }

        @include res($resmd) {
            @include fontsize(18);
            border-left: 2px solid #999;
            padding-top: 3px;
            padding-bottom: 3px;
            padding-left: 15px;
            margin-bottom: 30px;

            br {
                display: none;
            }
        }
    }

    @include res($resmd) {
        p {
            @include fontsize(14);
            padding-left: 0;
            padding-right: 0;
            line-height: 1.78571428571;
        }
    }
}
