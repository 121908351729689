@charset "UTF-8";

.flow_box2 {
    padding-top: 80px;
    padding-bottom: 120px;
    .flow_wrap {
        border: 1px solid #999;
        background-color: #fff;
        .flow_white {
            position: relative;
            margin: 15px;
            background-color: #f0f1ec;
            .flow_title {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                @include font_m;
                width: 262px;
                height: 72px;
                &::after {
                    content: "";
                    position: absolute;
                    right: -36px;
                    top: 0;
                    border-top: 72px solid #fff;
                    border-right: 36px solid transparent;
                }
            }
            .flow_img {
                max-width: 651px;
                padding-top: 71px;
                padding-bottom: 42px;
                margin: auto;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    @include res($resmd) {
        padding-top: 40px;
        padding-bottom: 60px;
        .flow_wrap {
            border: 0;
            .flow_white {
                margin: 0;
                .flow_title {
                    width: 192px;
                    height: 50px;
                    &::after {
                        right: -24px;
                        border-top: 50px solid #fff;
                        border-right: 24px solid transparent;
                    }
                }
                .flow_img {
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-top: 79px;
                    padding-bottom: 32px;
                }
            }
        }
    }
}

.flow_box3 {
    padding-bottom: 80px;
    .flow_contact {
        border: 1px solid #999;
        border-radius: 7px;
        padding: 40px 50px;
        margin-bottom: 40px;
        dl {
            border-bottom: 1px solid #999;
            padding-bottom: 28px;
            margin-bottom: 28px;
            dt {
                padding-bottom: 5px;
            }
            dd {
                display: flex;
                .flow_inquiry {
                    color: #fff;
                    text-decoration: none;
                }
                .flow_freeDial {
                    width: 40px;
                    margin-right: 12px;
                    color: #0f0f65;
                }
                a {
                    color: #0f0f65;
                    text-decoration: underline;
                }
            }
            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: 0;
            }
        }
    }
    .flow_txt {
        text-align: justify;
    }
    @include res($resmd) {
        padding-bottom: 40px;
        .flow_contact  {
            padding: 18px;
            margin-bottom: 20px;
            dl {
                padding-bottom: 19px;
                margin-bottom: 19px;
            }
        }
    }
}

.flow_box4 {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #f3f4ef;
    @include res($resmd) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.flow_box5 {
    padding-top: 80px;
    padding-bottom: 80px;
    @include res($resmd) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.flow_box6 {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #f3f4ef;
    @include res($resmd) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
.flow_box7 {
    padding-top: 80px;
    padding-bottom: 80px;
    @include res($resmd) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
.flow_box8 {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #e7eef4;
    @include res($resmd) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
.flow_box9 {
    padding-top: 80px;
    padding-bottom: 120px;
    border-bottom: 1px solid #ccc;
    @include res($resmd) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.flow_top {
    padding-bottom: 40px;
}

.flow_title {
    text-align: left;
}
