@charset "UTF-8";

.blog_list_container {
    display: flex;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 120px;
    border-bottom: 1px solid #999;

    .blog_list_left {
        width: 65.8035714286%;
        padding-right: 11.4285714286%;
    }

    .blog_list_right {
        width: 23.0357142857%;
    }
    .blog_select {
        display: none;
    }
    img {
        width: 100%;
        height: auto;
    }
    @include res($resmd) {
        display: block;
        padding-top: 40px;
        padding-bottom: 60px;
        .blog_list_left {
            width: 100%;
            padding-right: 0;
            padding-bottom: 60px;
        }

        .blog_list_right {
            width: 100%;
            .blog_list_box3 {
                ul {
                    display: none;
                }
            }
            .blog_list_box4 {
                ul {
                    display: none;
                }
            }
            section {
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
        .blog_select {
            display: block;
        }
    }
}

.blog_list_box2, .blog_list_box3, .blog_list_box4 {
    padding-bottom: 48px;
    @include res($resmd) {
        padding-bottom: 38px;
    }
}

.blog_list_a {
    display: block !important;
    .blog_cat {
        color: #999999;
    }
}
