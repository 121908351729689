@charset "UTF-8";

.price_container {
    padding-top: 80px;
    padding-bottom: 120px;
    border-bottom: 1px solid #999;
    img {
        width: 100%;
        height: auto;
    }
    @include res($resmd) {
        padding-top: 40px;
        padding-bottom: 35px;
    }
}

.price_wrap {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 80px;
    .price_left {
        width: 48.75%;
        .price_text {
            text-align: justify;
            letter-spacing: 0.01em;
            padding-bottom: 34px;
            line-height: 1.8125;
        }
    }
    .price_right {
        width: 40.0892857143%;
        .price_subTitle {
            margin-bottom: 7px;
            &::after {
                content: none;
            }
        }
        .price_table1 {
            margin-bottom: 32px;
        }
        .price_table2 {

        }
    }
    @include res($resmd) {
        display: block;
        padding-top: 23px;
        padding-bottom: 60px;
        .price_left {
            width: 100%;
            .price_text {
                line-height: 1.78571428571;
            }
            .price_img {
                padding-bottom: 33px;
            }
        }
        .price_right {
            width: 100%;
        }
    }
}

.price_box1 {

}
.price_box2 {

}
.price_box3 {
    .price_wrap {
        padding-bottom: 0;
    }

}
